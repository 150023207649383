import saveSearchUrl from './saveSearchUrl'
import pendingServiceCalls from './pendingServiceCalls'
import setVH from './setVH'

const tasks = [
  saveSearchUrl,
  pendingServiceCalls,
  setVH
]

tasks.forEach(task => { task.call(null) })
