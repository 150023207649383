import { saveToSessionStorage, fetchFromSessionStorage, removeFromSessionStorage } from '../lib/utils/browserStorage'
import { smoothScrollTo } from '../lib/utils/dom'
import { SEARCH_HISTORY_URL, SEARCH_SCROLL_POSITION } from '../lib/constants'

const SEARCH_URL_REGEXP = /\/for-sale|\/for-rent/i
const PROPERTY_URL_REGEXP = /\/properties\/(sales|rentals)/i

const isSearchUrl = url => !!url.match(SEARCH_URL_REGEXP)
const isPropertyUrl = url => !!url.match(PROPERTY_URL_REGEXP)

const scrollToSearchPosition = currentUrl => {
  const storedScroll = fetchFromSessionStorage(SEARCH_SCROLL_POSITION)

  if (storedScroll) {
    const scroller = document.getElementById(storedScroll.scrollerNodeId)

    if (storedScroll.url === currentUrl) {
      smoothScrollTo(storedScroll.position, scroller)
    }
    removeFromSessionStorage(SEARCH_SCROLL_POSITION)
  }
}

export default () => {
  document.addEventListener('turbolinks:load', () => {
    const currentUrl = global.location.toString()

    if (isSearchUrl(currentUrl)) {
      saveToSessionStorage(SEARCH_HISTORY_URL, currentUrl)
      scrollToSearchPosition(currentUrl)
    }

    if (!isSearchUrl(currentUrl) && !isPropertyUrl(currentUrl)) {
      removeFromSessionStorage(SEARCH_SCROLL_POSITION)
    }
  })
}
