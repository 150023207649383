// using in css
// height: calc(var(--vh, 1vh) * 100);

const setVH = () => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

export default () => {
  window.addEventListener('load', setVH)
  window.addEventListener('resize', setVH)
}

